import Vue                            from 'vue'
import VueSweetalert2                 from 'vue-sweetalert2';
import App                            from './App.vue'
import Snotify, { SnotifyPosition }   from 'vue-snotify';
import 'vue-snotify/styles/material.css';

//-------------------------------------------
//-- VueSweetAlert2 = SWAL
//-------------------------------------------
const optionsSwal = {
  confirmButtonColor: '#595cde',
  cancelButtonColor: '#fd8b0d',
};

Vue.use(VueSweetalert2, optionsSwal);

//-------------------------------------------
//-- Vue-Snotify = Snotify
//-------------------------------------------
const optionsSnotify = {
  toast: {
    position: SnotifyPosition.rightTop
  }
}

Vue.use(Snotify, optionsSnotify);

//-- Multi-Select 
import VueSelectSides from "vue-select-sides";
Vue.use(VueSelectSides, {locale: "es_ES"});
Vue.component("vue-select-sides", VueSelectSides);
import "vue-select-sides/styles/themes/soft.scss";

//-- Vue Draggable
import draggable from "vuedraggable";
Vue.component("draggable", draggable);


//----------------------
//-- FontAwesome
//----------------------

//-- Core
import { library } from '@fortawesome/fontawesome-svg-core'
//-- Icons
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
//-- Vue
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//-- Añadir Iconos a la Biblioteca
library.add(faUserSecret)
//-- Importar Iconos
import '@fortawesome/fontawesome-free/css/all.css'
//-- Usar el componente
Vue.component('font-awesome-icon', FontAwesomeIcon)

//-------------------
//-- Vue Side Menu
//-------------------
import VueSidebarMenu from 'vue-sidebar-menu'
//import "./igs-sidebar-menu.scss";
import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
//import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
Vue.use(VueSidebarMenu);


//-------------------------------------------
//-- LeafLet 
//-------------------------------------------
import { LMap, LTileLayer, LIcon, LMarker, LPopup, LControl, LControlZoom, LPolygon, LPolyline } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-icon', LIcon);
Vue.component('l-marker', LMarker);
Vue.component('l-polygon', LPolygon);
Vue.component('l-polyline', LPolyline);
Vue.component('l-popup', LPopup);
Vue.component('l-control', LControl);
Vue.component('l-control-zoom', LControlZoom);

import { Icon } from 'leaflet';

//-----------------
//-- HotLine
//-----------------
import LHotLine from 'vue2-leaflet-hotline/Vue2LeafletHotline';
Vue.component('v-hotline', LHotLine);

//-----------------------
//-- Polyline Decorator
//-----------------------
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator';
Vue.component('v-polyline-decorator', Vue2LeafletPolylineDecorator);


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl:  require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl:        require('leaflet/dist/images/marker-icon.png'),
  shadowUrl:      require('leaflet/dist/images/marker-shadow.png'),
});

//-----------------------
//-- ToolBar Drawing
//-----------------------
import LDrawToolbar from 'vue2-leaflet-draw-toolbar';
Vue.component('l-draw-toolbar', LDrawToolbar);



//-------------------------------------------
//-- Slider
//-------------------------------------------
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
Vue.component('VueSlider', VueSlider)


//-------------------------------------------
//-- Menú de Contexto (Botón Derecho Ratón)
//-------------------------------------------
import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);

//-------------------------------------------
//-- Axios (Acceso a URLs)
//-------------------------------------------
window.axios = require('axios');

//-------------------------------------------
//-- Ventana Modal (https://euvl.github.io/vue-js-modal)
//-------------------------------------------
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal, { componentName: 'VModal' });


//-------------------------------------------------------------------------
//-- Toggle Personalizable (https://github.com/euvl/vue-js-toggle-button)
//-------------------------------------------------------------------------
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

//-------------------------------------------------------------------------
//-- Popover (https://github.com/euvl/vue-js-popover)
//-------------------------------------------------------------------------
import Popover from 'vue-js-popover'
Vue.use(Popover, { tooltip: true })

//-------------------------------------------
//-- Importar Otros Módulos Adicionales
//-------------------------------------------
import igs_routes                       from '@/igs-routes/igs_routes'
import igs_store                        from '@/igs-store/igs_store'

//-- Elementos Visuales
import 'bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
//import "./app.scss"

//-- Bootstrap
require('bootstrap');

//-- Importacion de JQuery
window.$ = window.jQuery = require('jquery');

//-- Leaflet Full Screen
//import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
//import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';

//-- Determinar si estamos en modo Desarrollo / Producción
Vue.config.productionTip = false

//-- Variables de Acceso Global (y, generalmente, estático)
global.lnkAppName                   = "Panel iMaps";                     //-- Nombre de la App
global.lnkAppVersion                = "1.00-250203"                      //-- Versión de la App

global.lnkServerAppURL              = 'https://app.imaps.es/app?';      //-- URL de acceso a los Datos del Servidor Remoto (Contenido)
global.lnkServerImgURL              = 'https://panel.imaps.es/img/';    //-- URL para Descarga de Imágenes

global.lnkGetDataOk                 = 'im1-OK';                           //-- Tag OK
global.lnkGetDataError              = 'im1-ERROR';                        //-- Tag ERROR

global.lnkDateSeparator             = "-";                              //-- Separador para Fechas ("-", "/", ...)
global.lnkTimeSeparator             = ":";                              //-- Separador para Horas  (":", ...)

global.lnkMinDate                   = "00000101";                       //-- Fecha Mínima
global.lnkMaxDate                   = "99991231";                       //-- Fecha Máxima

global.lnkFactorMillas2Kms          = 1.852;                            //-- Factor de Conversión de Millas a Kilómetros (y viceversa)

global.lnkUsuarioDemo               = "U000000";                        //-- Cuenta de Usuario (Demo)
global.lnkDescripcionUsuarioDemo    = "Usuario Demostración (iMaps)";   //-- Descripción Usuario Demostración
global.lnkMailUsuarioDemo           = "demo@imaps.es";                  //-- Mail del Usuario Demostración

global.lnkInicialDiaSemana          = ['L','M','X','J','V','S','D'];    //-- Inicial de cada Día de la Semana

//-- CSS (Paleta Colores)  (tc=Text Color // bc = Background Color)
global.lnkDefaultCSS                = '.ns-root { --tc0 : #ffffff; --tc1 : #595cde; --tc2 : #fd8b0d; --tc3 : #fd8b0d; --tc4 : #595cde; --tc5 : #fd8b0d; --tc6 : #ffffff; --tc7 : #7f7f7f; --tc8 : #dedede; --tc9 : #ffffff; --bc0 : #595cde; --bc1 : #595cde; --bc2 : #fd8b0d; --bc3 : #0369cf; --bc4 : #595cde; --bc5 : #ffffff; --bc6 : #fd8b0d; --bc7 : #7f7f7f; --bc8 : #dedede; --bc9 : #ffffff; }';
global.lnkDefaultLIC                = ["#595cde", "#595cde", "#fd8b0d", "#dedede", "#7f7f7f"];


//-- Paleta de Colores para Globos/Markers/....
global.lnkPaletaColoresMarkers = [
  ['#34568B','#FFFFFF'],
  ['#FF6F61','#FFFFFF'],
  ['#6B5B95','#FFFFFF'],
  ['#88B04B','#FFFFFF'],
  ['#F7CAC9','#000000'],
  ['#92A8D1','#000000'],
  ['#955251','#FFFFFF'],
  ['#B565A7','#FFFFFF'],
  ['#009B77','#FFFFFF'],
  ['#DD4124','#FFFFFF'],
  ['#D65076','#FFFFFF'],
  ['#45B8AC','#FFFFFF'],
  ['#EFC050','#FFFFFF'],
  ['#5B5EA6','#FFFFFF'],
  ['#9B2335','#FFFFFF'],
  ['#DFCFBE','#000000'],
  ['#55B4B0','#000000'],
  ['#E15D44','#000000'],
  ['#7FCDCD','#000000'],
  ['#BC243C','#FFFFFF'],
  ['#C3447A','#FFFFFF'],
  ['#98B4D4','#000000'],
  ['#FDAC53','#FFFFFF'],
  ['#9BB7D4','#000000'],
  ['#B55A30','#FFFFFF'],

  ['#F5D4FD','#000000'],
  ['#0072B5','#FFFFFF'],
  ['#A0DAA9','#000000'],
  ['#E9897E','#FFFFFF'],
  ['#00A170','#FFFFFF'],
  ['#926AA6','#FFFFFF'],
  ['#D2386C','#FFFFFF'],
  ['#363945','#FFFFFF'],
  ['#939597','#FFFFFF'],
  ['#EFE1CE','#000000'],
  ['#E0B589','#000000'],
  ['#9A8B4F','#FFFFFF'],
  ['#CD212A','#FFFFFF'],
  ['#FFA500','#000000'],
  ['#56C6A9','#000000'],
  ['#4B5335','#FFFFFF'],
  ['#798EA4','#FFFFFF'],
  ['#FA7A35','#FFFFFF'],
  ['#00758F','#FFFFFF'],
  ['#EDD59E','#000000'],
  ['#E8A798','#000000'],
  ['#9C4722','#FFFFFF'],
  ['#6B5876','#FFFFFF'],
  ['#B89B72','#FFFFFF'],
  ['#282D3C','#FFFFFF'],

  ['#A09998','#FFFFFF'],
  ['#DC793E','#FFFFFF'],
  ['#A2242F','#FFFFFF'],
  ['#C48A69','#FFFFFF'],
  ['#D9CE52','#000000'],
  ['#D19C97','#000000'],
  ['#006B54','#FFFFFF'],
  ['#6A2E2A','#FFFFFF'],
  ['#E6AF91','#000000'],
  ['#6C244C','#FFFFFF'],
  ['#DD4132','#FFFFFF'],
  ['#FF6F61','#FFFFFF'],
  ['#C62168','#FFFFFF'],
  ['#755139','#FFFFFF'],
  ['#D69C2F','#000000'],
  ['#616247','#FFFFFF'],
  ['#E8B5CE','#000000'],
  ['#343148','#FFFFFF'],
  ['#615550','#FFFFFF'],
  ['#77212E','#FFFFFF'],
  ['#577284','#FFFFFF'],
  ['#264E36','#FFFFFF'],
  ['#F3E0BE','#000000'],
  ['#2A293E','#FFFFFF'],
  ['#9F9C99','#FFFFFF'],
  
  ['#797B3A','#FFFFFF'],
  ['#944743','#FFFFFF'],
  ['#DBB1CD','#000000'],
  ['#EC9787','#000000'],
  ['#00A591','#FFFFFF'],
  ['#6C4F3D','#FFFFFF'],
  ['#EADEDB','#000000'],
  ['#BC70A4','#FFFFFF'],
  ['#BFD641','#000000'],
  ['#2E4A62','#FFFFFF'],
  ['#B4B7BA','#000000'],
  ['#C0AB8E','#000000'],
  ['#7F4145','#FFFFFF'],
  ['#BD3D3A','#FFFFFF'],
  ['#3F69AA','#FFFFFF'],
  ['#D5AE41','#000000'],
  ['#766F57','#FFFFFF'],
  ['#E47A2E','#FFFFFF'],
  ['#BE9EC9','#000000'],
  ['#F1EA7F','#000000'],
  ['#006E6D','#000000'],
  ['#485167','#FFFFFF'],
  ['#D1B894','#000000'],
  ['#BCBCBE','#000000'],
  ['#A9754F','#000000']
];


//-----------------------------------------------------------------------------------------------
//-- Configurar RUTAS para ser usadas a lo largo de toda la App
//-----------------------------------------------------------------------------------------------
Vue.prototype.$lnkListaPaginas = igs_routes

//-----------------------------------------------------------------------------------------------
//-- Configurar STORE para ser usado a lo largo de toda la App
//-----------------------------------------------------------------------------------------------
Vue.prototype.$store = igs_store

/* eslint-disable */
const app = new Vue({
  render: h => h(App),
  router:igs_routes,
  store:igs_store,
  data: { },
  created() {
    window.addEventListener('resize', this.gp99HandleResize);
    this.gp99HandleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.gp99HandleResize);
  },
  mounted() {

    //console.log('### App Mounted ###  (Inicio)');

    //console.log('### App Mounted ###  (Fin)');

    /*
    //-- Ejemplo de cómo llamar a Métodos VUE desde JQuery
    //-- Se puede llamar con app.XXXX a métodos locales definidos en la
    //-- instancia de VUE, y esos métodos locales Javascript (definidos en methods
    //-- durante la instanciación de VUE) ya ¡¡ pueden llamar a métodos VUE !!
    $(function() {
      $(document).on('click', '.alert-close', function() {
          //-- Ocultar Anuncio actual
          $(this).parent().hide();

          //-- Inicializar Texto
          if ($(this).parent()[0].id == 'igs-foot-alert') {
            app.gm10StrFootMsg();   //-- Pie (Footer)
          } else {
            app.gm10StrHeadMsg();   //-- Cabecera (Heading)
          }
      })
    });
    */
  },
  methods: {

    //-- Actualizar Ancho y Alto de la Ventana
    gp99HandleResize() {
      //console.log('-- gp99HandleResize --');
      //console.log(window.innerWidth + ' / '+window.innerHeight)
      this.$store.commit('p10StrScreenWidth', window.innerWidth);
      this.$store.commit('p10StrScreenHeight', window.innerHeight);
    }

    /*
    //-- Ejemplo de métodos locales JavaScript que realizan llamada
    //-- a métodos VUE. Con frecuencia estos métodos serán utilizados
    //-- por métodos y funciones JQuery

    //-- Global Method : Inicialización Texto del Mensaje de la parte superior (Heading)
    gm10StrHeadMsg: function() {
      //console.log('[gm10StrHeadMsg] ('+texto+')');
      //this.$store.commit('p10StrHeadMsgTexto', '');
    },
    //-- Global Method : Inicialización Texto del Mensaje de la parte inferior (Footer)
    gm10StrFootMsg: function() {
      //console.log('[gm10StrFootMsg] ('+texto+')');
      //this.$store.commit('p10StrFootMsgTexto', '');
  }
  */

}
}).$mount('#app')
/* eslint-enable */

//-- Iniciar la App en la Página Principal
igs_routes.replace('/')

//-- Comprobación de Arranque correcto de JQuery
// eslint-disable-next-line no-undef
$(document).ready(function () {
  //console.log('JQuery ¡¡ ON !!');

  //-- Ejemplo de llamada a método local Javascript (que, a su vez, realiza llamada a métodos VUE)
  //app.gm10StrHeadMsg('1234'); 
})


//---------------------------------------------------------------
//-- Anotaciones / Comentarios / Tips / Ideas / .....
//---------------------------------------------------------------

/*

  //==================================================================
  //=========  Ajustes sobre Codigo Fuente de Librerias ==============
  //==================================================================

  
  1) Zoom : Sólo se alcanza el Zoom (18)  [en lugar del Límite (21)]
  ----------------------------------------------------------------------
     Error : Una vez alcanzado el Límite de Zoom (18) el botón (+) aparece
            desactivado, y no es posible ampliar el Zoom y visualizar el
            nombre de calles pequeñas y poco importantes
     Solución : Bajo la ruta [node_modules/leaflet] se debe "buscar & reemplazar"
            la expresión "maxZoom: 18" por "maxZoom: 21"





*/



