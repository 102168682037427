<!--
#########################################################################################
######    Vista SVC00000  :  Pantalla de LOGIN                                     ######
#########################################################################################
-->

<template>
<div class="jumbotron vertical-center igs-back-color9" v-if="swMostrarFormularioLogin"> 
  <div class="container">

      <TNC10000></TNC10000>

      <div class="row">
          <div class="col-12 igs-center" @click="lp90MostrarVersion()">
            <img :src="f99ImageName('im1-logo-cuadro-mando')" height="140" > <br/>
          </div>
          <div class="col-12">              
            <br/><br/>
          </div>
          <div class="col-12">
            <img :src="f99ImageName('im1-usuario-azul')" width="80"> <br/><br/>
          </div>
      </div>

      <div class="row">
          <div class="col-1">&nbsp;</div>
          <div class="col-2 igs-center">
            <img :src="f99ImageName('im1-mail-azul')" width="48"> 
          </div>
          <div class="col-8 igs-left igs-l-pad-20 igs-v-center">

             <div class="form-group">
                <input type="email" v-model="correo" :isEnabled="!$store.state.strRecibiendoDatos" class="form-control igs-center" aria-describedby="hEMail" placeholder="Dirección de Correo">
                <small id="hEmail" class="form-text text-muted"></small>
             </div>

          </div>
          <div class="col-1">&nbsp;</div>
      </div>

      <div class="row">
          <div class="col-1">&nbsp;</div>
          <div class="col-2 igs-center">
            <img :src="f99ImageName('im1-candado-azul')" width="48"> 
          </div>
          <div class="col-8 igs-left igs-l-pad-20 igs-v-center">
             <div class="form-group">
                <input type="password" v-model="password" :isEnabled="!$store.state.strRecibiendoDatos" class="form-control igs-center" aria-describedby="hPassword" placeholder="Contraseña">
                <small id="hPassword" class="form-text text-muted"></small>
             </div>
          </div>
          <div class="col-1">&nbsp;</div>
      </div>

      <div class="row">
          <div class="col-12 igs-center">
            <br/>
            <img :src="f99ImageName(lf99ImagenOK)" width="48" @click="lp70Login(false)" v-if="!$store.state.strRecibiendoDatos"> 
            <div v-if="$store.state.strRecibiendoDatos" class="igs-color2 igs-bold igs-center">Accediendo al Panel ...</div>
          </div>
      </div>

      <div class="row" style="height:80px">
        &nbsp;
      </div>

      <div class="row">
        <div class="col-5 igs-center" @click="lp70RecuperarPassword()">Recuperar Contraseña</div>
        <div class="col-2 igs-center">&nbsp;|&nbsp;</div> 
        <div class="col-5 igs-center" @click="lp70Login(true)">DEMO</div>
      </div>

  </div>

  <!--
  <div class="fixed-bottom">
    <br/>
  </div>
  -->
</div>

</template>

<script>
import igs_mixin from '@/igs-mixins/igs_mixin'
const  axios = require("axios");

import TNC10000  from '@/igs-navegacion/TNC10000'

export default {
  mixins: [ igs_mixin ],
  components : { TNC10000 },

  //=======================================================================
  //------------------------ Variables Locales  ---------------------------
  //=======================================================================
  data() {
    return {
      correo : '',
      password : '',
      lvVistaLogoUsuario : null,
      swMostrarFormularioLogin : false
    }
  },

  //=======================================================================
  //------------------------ Variables Calculadas  ---------------------------
  //=======================================================================
  computed : {

      //-------------------------------------------------------------------
      //-- Nombre de la Imagen a Mostrar para presionar OK
      //-------------------------------------------------------------------
      lf99ImagenOK () {

          if ((this.correo == "") || (this.password == "")) {
              return "im1-ok-gris"
          } else {
              return "im1-ok-azul"
          }

      }
  },


  //=======================================================================
  //------------------------ Métodos Locales  ---------------------------
  //=======================================================================
  methods: {

    //--------------------------------------------------------------------------------
    //-- Animación Inicial al Mostrar la Página de Login
    //--------------------------------------------------------------------------------
    // eslint-disable-next-line no-unused-vars
    p10PaginaCargada () {

      //-- Ocultar Indicadores de Actividad
      this.$store.commit('p10StrRecibiendoDatos', false);

      //-- Inicializar Indicador Menu Principal
      this.$store.commit('p10StrMenuPrincipal', false); 

      //-- Determinar la URL a contactar
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&t=999&m=999";
      console.log(URL);
        
      //-- Solicitud de Datos al Servidor 
      // eslint-disable-next-line no-unused-vars
      axios.get(URL, {responseEncoding: 'utf-8'}).then(respuesta => {
        //console.info('Sesión Destruida con Éxito');
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        console.info('Error al tratar de destruir la sesión')
      });

      //----------------------------------------------------------------------------------------
      //-- Destruir (localmente) los valores relacionados con la (posible) Sesión Anterior
      //----------------------------------------------------------------------------------------
      this.$store.commit('p10StrIDSesion', '00000000000000123456');
      this.$store.commit('p10StrUsuarioAutenticado', false);
      this.$store.commit('p10StrAbandonarSesion', false);

      this.$store.commit('p10StrUsuario', global.lnkUsuarioDemo);
      this.$store.commit('p10StrDescripcionUsuario', global.lnkDescripcionUsuarioDemo);
      this.$store.commit('p10StrMailUsuario', global.lnkMailUsuarioDemo);

      this.$store.commit('p10StrDistribuidor', '');
      this.$store.commit('p10StrDescripcionDistribuidor', '');
      this.$store.commit('p10StrDistribuidorListaImagenColor', global.lnkDefaultLIC);
      this.$store.commit('p10StrNifDistribuidorOfuscado', '');
      this.$store.commit('p10StrDistribuidorMapaAtribucion', '');
      this.$store.commit('p10StrDistribuidorUrlErp', '');
      this.$store.commit('p10StrDistribuidorUrlMapa', '');
      this.$store.commit('p10StrDistribuidorUrlOptimizador', '');
      this.$store.commit('p10StrDistribuidorGrupoDispositivos', '-1');
      //this.p90AplicarCSS(global.lnkDefaultCSS);
      //this.p90AplicarEstiloSB(global.lnkDefaultLIC[0]); 

      this.swMostrarFormularioLogin = true;

      //-- Debug
      //console.log('-- Prueba SHA-256 --');
      //console.log(this.f70SHA256('998888881119500280000554052000000031235009782SHA2http://www.ceca.eshttp://www.ceca.es','1'));
    },


   //--------------------------------------------------------------------------------
   //-- Ocultar Teclado
   //--------------------------------------------------------------------------------
    lp10OcultarTeclado() {
    },

    //--------------------------------------------------------------------------------
    //-- Posicionarse en el campo Correo
    //--------------------------------------------------------------------------------
    lp90FocusCorreo() {
    },


    //--------------------------------------------------------------------------------
    //-- Posicionarse en el campo Password
    //--------------------------------------------------------------------------------
    lp90FocusPassword() {
    },

    //--------------------------------------------------------------------------------
    //-- Validación del Usuario y Contraseña (y Login en caso de éxito)
    //--------------------------------------------------------------------------------
    lp70Login(modoDemo) {

      //console.log('Intento del Login del Mail ('+this.correo+')');

      //-- Determinar la URL a contactar
      var valorMetodo = "091";
      if (modoDemo) { valorMetodo = "991"; }

      var URL = global.lnkServerAppURL + "&s=&t=999&m="+valorMetodo+"&e="+this.f75OfuscarTexto(this.correo)+"&p="+this.f75OfuscarTexto(this.password);
      console.log(URL);

      //-- Mostrar el/los indicadores de actividad oportunos
      this.$store.commit('p10StrRecibiendoDatos', true);
        
      //-- Solicitud de Datos al Servidor 
      axios.get(URL, {responseEncoding: 'utf-8'})
      .then(respuesta => {
      
            //console.log('Respuesta : ');
            console.log(respuesta.data);

            //-- Desactivar los Indicadores de Actividad
            this.$store.commit('p10StrRecibiendoDatos', false);

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            // eslint-disable-next-line no-unused-vars
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            if ((datos.flg == global.lnkGetDataOk) && (datos.sesion)) {

            if (datos.usuario) {
                console.log('Nuevo Acceso OK');

                //----------------------------------------------------------------------------------------
                //-- Conservar las propiedades del NUEVO USUARIO
                //----------------------------------------------------------------------------------------
  
                if (modoDemo) {
                  this.$store.commit('p10StrUsuario', global.lnkUsuarioDemo);
                  this.$store.commit('p10StrDescripcionUsuario', global.lnkDescripcionUsuarioDemo);
                  this.$store.commit('p10StrMailUsuario', global.lnkMailUsuarioDemo);
                } else {
                  this.$store.commit('p10StrUsuario', datos.usuario);
                  this.$store.commit('p10StrDescripcionUsuario', datos.descripcionUsuario);
                  this.$store.commit('p10StrMailUsuario', this.correo);
                }

                //-- Conservar la Relación de Distribuidores/Clientes
                this.$store.commit('p10StrListaUD', datos.listaUD);

                //----------------------------------------------------------------------------------------
                //-- Conservar el Nro. de Sesión, y asumir "Sesión Viva"
                //----------------------------------------------------------------------------------------
                this.$store.commit('p10StrIDSesion', datos.sesion);
                this.$store.commit('p10StrUsuarioAutenticado', true);
                this.$store.commit('p10StrAbandonarSesion', false);

                //----------------------------------------------------------------------------------------
                //-- Establecer como Valor Actual el PRIMER Distribuidor/Cliente de la Lista Recibida 
                //----------------------------------------------------------------------------------------
                if (datos.listaUD.length > 0) {

                  //-- Conservar las propiedades del NUEVO DISTRIBUIDOR
                  this.$store.commit('p10StrDistribuidor', datos.listaUD[0].d1);
                  this.$store.commit('p10StrDescripcionDistribuidor', datos.listaUD[0].d2);
                  this.$store.commit('p10StrDistribuidorListaImagenColor', datos.listaUD[0].dlic);
                  this.$store.commit('p10StrNifDistribuidorOfuscado', this.f75OfuscarTexto(datos.listaUD[0].d3));
                  this.$store.commit('p10StrDistribuidorMapaAtribucion', datos.listaUD[0].d6);
                  this.$store.commit('p10StrDistribuidorUrlErp', datos.listaUD[0].d7);
                  this.$store.commit('p10StrDistribuidorUrlMapa', datos.listaUD[0].d8);
                  this.$store.commit('p10StrDistribuidorUrlOptimizador', datos.listaUD[0].d9);
                  this.$store.commit('p10StrDistribuidorGrupoDispositivos', datos.listaUD[0].dg);
                  //this.p90AplicarCSS(datos.listaUD[0].dcss);
                  //this.p90AplicarEstiloSB(datos.listaUD[0].dlic[0]); 

                  //-- Actualizar Parámetros de Configuración
                  this.p98t004m003();


                  //.................................
                  //-- Determinar Rango D/H Fecha
                  //.................................
                  var hoy = new Date();

                  //-- Revisar Desde Fecha
                  this.$store.commit('p10StrDesdeFechaConsulta', this.f92Date2AMD(new Date(hoy.getTime() - (7 * 24 * 60 * 60 * 1000)))); 

                  //-- Revisar Hasta Fecha
                  this.$store.commit('p10StrHastaFechaConsulta', this.f92Date2AMD(new Date(hoy.getTime() - (1 * 24 * 60 * 60 * 1000)))); 
                  
                  //................................................
                  //-- Mostrar el Opción Principal
                  //................................................
                  this.$router.push('/41000');

                  //................................................
                  //-- Obtener la Lista de Dispositivos disponible
                  //................................................
                  this.p98t001m501();

                  //.........................................................
                  //-- Obtener la Lista de Operarios y Camiones disponibles
                  //.........................................................
                  this.p98t012m003();
                  this.p98t013m003();

                } else {

                    this.p99AnuncioIM1('2','7','ERROR', '(G00) No hay vínculo con ningún Distribuidor',-1);

                }
                
              } else {
                 this.p99AnuncioIM1('2','7','ERROR', '(G00) Código de Usuario Desconocido',-1);
              }


            } else {
              console.dir(datos);
              if (datos.msg) { 
                 this.p99AnuncioIM1('2','7','ERROR', this.f79DecodeHTML(datos.msg),-1);
              } else {
                 this.p99AnuncioIM1('2','7','ERROR', '(E999001) Error Desconocido',-1);
              }

            }


        })
        .catch(error => {

          console.dir(error);
          
          //-- Desactivar los Indicadores de Actividad
          this.$store.commit('p10StrRecibiendoDatos', false);

          //-- Mostrar el Mensaje de Error oportuno
          this.p99AnuncioIM1('2','7','ERROR', error.message,-1);
        });

    },

    //----------------------------------------------------------
    //--- Acudir a la Pantalla Recuperación de Contraseña
    //----------------------------------------------------------
    lp70RecuperarPassword () {
        
      //-- Mostrar el Menu Principal
      this.$router.push('/00801');

    },

    //----------------------------------------------------------
    //--- Mostrar Versión y Valores de Diagnóstico
    //----------------------------------------------------------
    lp90MostrarVersion() {
       this.p99AnuncioIM1('5','7',global.lnkAppName , 'Versión '+global.lnkAppVersion,10);
    },

    //----------------------------------------------------------
    //--- Automatización (desde "panel.imaps.local")
    //----------------------------------------------------------
    lp99RedireccionAutorizada() {

      //console.log('URL = '+window.location.href);

      var URL = window.location.href;
      var parametros = URL.split("/");

      //console.log('Longitud : '+parametros.length);

      //-- Tomar los Parámetros incrustados en la URL
      var ruta          = parametros[3];
      var distribuidor  = parametros[4];

      if ((parametros.length == 5) && ((ruta == "11000") || (ruta == "11616")) && (distribuidor.substring(0,1) == "D") && (distribuidor.length == 7)) {
        //console.log(' == EXITO ==');
      } else {
        //console.log('ERROR');
        return;
      }

      //----------------------------------------------------------------------------------------
      //-- Hacer uso de una Sesión de Último Recurso
      //----------------------------------------------------------------------------------------
      this.$store.commit('p10StrIDSesion', "998877665544332211cm");
      this.$store.commit('p10StrDistribuidor', distribuidor);

      //-- Solicitud de Datos al Servidor 
      URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&d="+this.$store.state.strDistribuidor+"&t=001&m=020"; 
      axios.get(URL, {responseEncoding: 'utf-8'}).then(respuesta => {
        //console.log('-- Respuesta --');
        //console.dir(respuesta);

        this.$store.commit('p10StrListaUD', respuesta.data);
        //this.$store.commit('p10Seleccionables', [{"value": "D000000","label": "Empresa ABC, S.L."}]);

        this.$store.commit('p10StrUsuarioAutenticado', true);
        this.$store.commit('p10StrAbandonarSesion', false);

        this.$store.commit('p10StrUsuario', global.lnkUsuarioDemo);
        this.$store.commit('p10StrDescripcionUsuario', global.lnkDescripcionUsuarioDemo);
        this.$store.commit('p10StrMailUsuario', global.lnkMailUsuarioDemo);

        //this.$store.commit('p10StrListaDistribuidoresSeleccionados', [respuesta.data.d1]);

        //-- Conservar las propiedades del NUEVO DISTRIBUIDOR
        this.$store.commit('p10StrDistribuidor', respuesta.data.d1);
        this.$store.commit('p10StrDescripcionDistribuidor', respuesta.data.d2);
        this.$store.commit('p10StrNifDistribuidorOfuscado', this.f75OfuscarTexto(respuesta.data.d3));
        this.$store.commit('p10StrDistribuidorMapaAtribucion', respuesta.data.d6);
        this.$store.commit('p10StrDistribuidorUrlErp', respuesta.data.d7);
        this.$store.commit('p10StrDistribuidorUrlMapa', respuesta.data.d8);
        this.$store.commit('p10StrDistribuidorUrlOptimizador', respuesta.data.d9);
        this.$store.commit('p10StrDistribuidorGrupoDispositivos', respuesta.data.dg);

        //-- Actualizar Parámetros de Configuración
        this.p98t004m003();

        //-- Redirección a la página solicitada
        this.$router.push('/'+ruta);

      }).catch(error => {        
        console.log(error);
        console.info('Error al tratar de obtener propiedades del Distribuidor '+this.$store.state.strDistribuidor);
      });

    },

  },

  //=======================================================================
  //------------ Métodos a aplicar una vez CARGADA la Página  -------------
  //=======================================================================
  mounted() {

    //-- LLamadas automáticas para confección de "report"
    //console.log('Hostname : '+window.location.hostname);

    //-- Tomar la URL completa
    var URL = window.location.href;

    //-- Determinar el número de fragmentos (parámetros) que vienen cargados
    var parametros = URL.split("/");

    //-- Determinar si se permite el Acceso Automatizado
    var swAccesoAutomatizado = false;

    //-- Informes Automatizados Panel iMaps
    if ((   (window.location.hostname == "panel.imaps.local") || 
            (window.location.hostname == "localhost") || 
            (window.location.hostname == "192.168.0.32") || 
            (window.location.hostname == "172.20.10.4")) &&             
         (parametros.length > 4)) {

          //.................................
          //-- Determinar Rango D/H Fecha
          //.................................
          var hoy = new Date();

          //-- Revisar Desde Fecha
          this.$store.commit('p10StrDesdeFechaConsulta', this.f92Date2AMD(new Date(hoy.getTime() - (7 * 24 * 60 * 60 * 1000)))); 

          //-- Revisar Hasta Fecha
          this.$store.commit('p10StrHastaFechaConsulta', this.f92Date2AMD(new Date(hoy.getTime() - (1 * 24 * 60 * 60 * 1000)))); 

          //-- Activar Acceso AUTOMATIZADO
          swAccesoAutomatizado = true;
    }

    //-- Pasarela de Pago (TPV Virtual RedSys)
    if (  (window.location.hostname == "panel.imaps.es") && 
          (parametros.length > 4) && 
        ( (parametros[3] == '91000') || (parametros[3] == '91010') || 
          (parametros[3] == '95000') || (parametros[3] == '95010') || 
          (parametros[3] == '99000') || (parametros[3] == '99010')) ) {
          swAccesoAutomatizado = true;
    }

    //-- Tolerar peticiones SIN SESION a partir de ORIGENES DE CONFIANZA
    console.log('swAccesoAutomatizado : '+swAccesoAutomatizado+'  longitudParametros : '+parametros.length);
    console.dir(parametros);

    //-- En caso de Acceso Automatizado
    if (swAccesoAutomatizado) {

      //-- Cargar Parámetros sobre el Store
      this.$store.commit('p10StrDistribuidor',                parametros[4]);
      this.$store.commit('p10StrUsuario',                     parametros[5]);
      this.$store.commit('p10StrIDSesion',                    parametros[6]);

      this.$store.commit('p10StrDesdeFechaConsulta',          parametros[7]);
      this.$store.commit('p10StrHastaFechaConsulta',          parametros[8]);

      this.$store.commit('p10StrLatitudCentroMapa',           parseFloat(parametros[9]));
      this.$store.commit('p10StrLongitudCentroMapa',          parseFloat(parametros[10]));
      this.$store.commit('p10StrZoomInicial',                 parseInt(parametros[11]));
      this.$store.commit('p10StrIndiceCartografia',           parseInt(parametros[12]));

      this.$store.commit('p10StrLeyenda1',                    this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[13])));
      this.$store.commit('p10StrLeyenda2',                    this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[14])));
      this.$store.commit('p10StrLeyenda3',                    this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[15])));

      //-- Actualizar Parámetros de Configuración
      this.p98t004m003();

      //-- Uso Futuro
      //this.$store.commit('p10Str????',                        parametros[16]);
      //this.$store.commit('p10Str????',                        parametros[17]);
      //this.$store.commit('p10Str????',                        parametros[18]);
      //this.$store.commit('p10Str????',                        parametros[19]);
      //this.$store.commit('p10Str????',                        parametros[20]);

      //-- Textos y URLs Ofuscados
      this.$store.commit('p10StrDistribuidorMapaAtribucion',    this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[21])));
      this.$store.commit('p10StrDistribuidorUrlErp',            this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[22])));
      this.$store.commit('p10StrDistribuidorUrlMapa',           this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[23])));
      this.$store.commit('p10StrDistribuidorUrlOptimizador',    this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[24])));
      this.$store.commit('p10StrDistribuidorGrupoDispositivos', this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[25])));

      //-- Transición
      console.log('-- parametros[26] --');
      console.log(this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[26])));

      //-- Datos del Proceso
      this.$store.commit('p10StrDatosDelProceso',             JSON.parse(this.f75DesofuscarTexto(this.f79DecodeHTML(parametros[26]))));

      console.log('-- strDatosDelProceso --');
      console.dir(this.$store.state.strDatosDelProceso);

      //-- //TODO//
      if (parametros[3] == '91000') {  this.$router.push('/91000'); }
      if (parametros[3] == '91010') {  this.$router.push('/91010'); }

      if (parametros[3] == '95000') {  this.$router.push('/95000'); }
      if (parametros[3] == '95010') {  this.$router.push('/95010'); }

      if (parametros[3] == '99000') {  this.$router.push('/99000'); }
      if (parametros[3] == '99010') {  this.$router.push('/99010'); }

      //-- Automatizar únicamente URLs que contengan parámetros en la URL
      if (parametros.length > 4) { this.lp99RedireccionAutorizada(); }

    } else {
      //-- Acceso NO Automatizado --> Solicitud de Usuario / Contraseña
      //console.log('SVC00000 mounted');
      this.p10PaginaCargada ();

    }
    
  }
}
</script>

<style>

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
</style>
