//-----------------------------------
//-- Bibliotecas a Importar
//-----------------------------------
import Vue from 'vue'

//-----------------------------------
//-- VUEX (Tratamiento del Store)
//-----------------------------------
import Vuex from 'vuex'
Vue.use(Vuex)


//-------------------------------------------------------------------------------------------
//-- STORE : Almacenamiento de Variables y evaluación de sus CAMBIOS (Mutaciones)
//-------------------------------------------------------------------------------------------


const igs_store = new Vuex.Store({

  //-------------------------------------------------
  //-- Estados (Variables)
  //-------------------------------------------------
  state : {

    strUsuarioAutenticado : true,
    strAbandonarSesion    : false,
    strMenuPrincipal      : true,

    strScreenWidth        : 0,
    strScreenHeight       : 0,

    //..................................

    strIndiceCartografia  : 0,

    strListaCartografias  : [
          { indice : 0,   titulo : 'OpenStreetMap',             url : 'https://map1.imaps.es/hot/{z}/{x}/{y}.png',              opciones : { maxZoom: 19 } },
          { indice : 1,   titulo : 'Google Maps (Standard)',    url : 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',     opciones : { maxZoom: 21, subdomains:["mt0","mt1","mt2","mt3"] }},
          { indice : 2,   titulo : 'Google Maps (Híbrido)',     url : 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',   opciones : { maxZoom: 21, subdomains:["mt0","mt1","mt2","mt3"] }},
          { indice : 3,   titulo : 'Google Maps (Satélite)',    url : 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',     opciones : { maxZoom: 21, subdomains:["mt0","mt1","mt2","mt3"] }},
          { indice : 4,   titulo : 'Google Maps (Terreno)',     url : 'https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',     opciones : { maxZoom: 21, subdomains:["mt0","mt1","mt2","mt3"] }}
    ],




    //..................................

    strDesdeFechaConsulta               : '00000101',
    strHastaFechaConsulta               : '99991231',

    strLatitudCentroMapa                : 0,
    strLongitudCentroMapa               : 0,
    strZoomInicial                      : 10,

    strDestinoSalidaHora                : 9,
    strDestinoSalidaMinutos             : 0,
    strDestinoSegundosParada            : 300,

    strParadaSegundosFactorCliente      : 120,
    strParadaSegundosFactorArticulo     : 25,
    strParadaSegundosCriterioArticulos  : 0,

    strRepartoTramosHorarios : [
      ['0001','0659'],
      ['0700','0859'],
      ['0900','1059'],
      ['1100','1259'],

      ['2400','2400'],

      ['1300','1459'],
      ['1500','1659'],
      ['1700','1859'],
      ['1900','2359']
    ],

    strDocumentoAtributosMostrables : ["S","N","N","N","N","N","N","N","N","N","N","N","N","N","N","N","N","N","N","N"],

    strCalendarioListaImagenColor : {
      primary:   '#595cde',
      secondary: '#93a0bd',
      ternary:   '#fd8b0d',
      border:    '#e6e6e6',
      light:     '#ffffff',
      dark:      '#000000',
      hovers: {
        day:     '#fd8b0d',
        range:   '#e6e6e6'
      }
    },

    //..................................

    strUsuario: 'U000000',
    strDescripcionUsuario: 'Usuario Demostración',
    strMailUsuario: 'demo@imaps.es',

    //..................................

    strDistribuidor: 'D000000',
    strDescripcionDistribuidor: 'Empresa ABC',
    strDistribuidorListaImagenColor : ["#000000", "#595cde", "#fd8b0d", "#0369cf", "#0000ff"],
    strNifDistribuidorOfuscado: 'VVdwamQwNVVUWGhPVkZWNg==',
    strDistribuidorMapaAtribucion: 'iProgram',
    strDistribuidorUrlErp: '',
    strDistribuidorUrlMapa: '',
    strDistribuidorUrlOptimizador: '',
    strDistribuidorGrupoDispositivos: '-1',

    strLeyenda1: '',
    strLeyenda2: '',
    strLeyenda3: '',
    strDatosDelProceso: '',

    //..................................

    strIDSesion: '000000000000123456cm',
    strListaUD:  [],
    strListaDispositivos:  [],

    strListaDistribuidores: [ 
      { cod:'D000000', des:'Empresa ABC', nif:'B70531553', css:'', lic:[], atr:'iProgram (Demo)'  }
    ],

    strListaDistribuidoresSeleccionables : [],
    strListaDistribuidoresSeleccionados  : [],

    strListaOperarios : [],
    strListaCamiones  : [],

    //..................................

    strRecibiendoDatos: false,
    strIndicadorRefrescarVista : 0,
    strIndicadorPorcentajeActividad : 0,

    //..................................

    
  },


  //-------------------------------------------------
  //-- Mutaciones (Cambios sobre Variables)
  //-------------------------------------------------
   mutations : {

      p10StrUsuarioAutenticado(state,valor)             {   state.strUsuarioAutenticado       = valor;    },
      p10StrAbandonarSesion(state,valor)                {   state.strAbandonarSesion          = valor;    },
      p10StrMenuPrincipal(state,valor)                  {   state.strMenuPrincipal            = valor;    },

      p10StrScreenWidth(state,valor)                    {   state.strScreenWidth              = valor;    },
      p10StrScreenHeight(state,valor)                   {   state.strScreenHeight             = valor;    },

      //..................................

      p10StrIndiceCartografia(state,valor)              {   state.strIndiceCartografia        = valor;    },
      p10StrListaCartografias(state,valor)              {   state.strListaCartografias        = valor;    },

      //..................................

      p10StrDesdeFechaConsulta(state,valor)             {   state.strDesdeFechaConsulta             = valor;    },
      p10StrHastaFechaConsulta(state,valor)             {   state.strHastaFechaConsulta             = valor;    },
      p10StrCalendarioListaImagenColor(state,valor)     {   state.strCalendarioListaImagenColor     = valor;    },

      p10StrLatitudCentroMapa(state,valor)              {   state.strLatitudCentroMapa              = valor;    },
      p10StrLongitudCentroMapa(state,valor)             {   state.strLongitudCentroMapa             = valor;    },
      p10StrZoomInicial(state,valor)                    {   state.strZoomInicial                    = valor;    },

      //..................................

      p10StrDestinoSalidaHora(state,valor)              {   state.strDestinoSalidaHora              = valor;    },
      p10StrDestinoSalidaMinutos(state,valor)           {   state.strDestinoSalidaMinutos           = valor;    },
      p10StrDestinoSegundosParada(state,valor)          {   state.strDestinoSegundosParada          = valor;    },

      p10StrParadaSegundosFactorCliente(state,valor)      {   state.strParadaSegundosFactorCliente      = valor;    },
      p10StrParadaSegundosFactorArticulo(state,valor)     {   state.strParadaSegundosFactorArticulo     = valor;    },
      p10StrParadaSegundosCriterioArticulos(state,valor)  {   state.strParadaSegundosCriterioArticulos  = valor;    },

      p10StrRepartoTramosHorarios(state,valor)            {   state.strRepartoTramosHorarios            = valor;    },

      p10StrDocumentoAtributosMostrables(state,valor)     {   state.strDocumentoAtributosMostrables     = valor;    },

      //..................................

      p10StrUsuario(state,valor)                        {   state.strUsuario                        = valor;    },
      p10StrDescripcionUsuario(state,valor)             {   state.strDescripcionUsuario             = valor;    },
      p10StrMailUsuario(state,valor)                    {   state.strMailUsuario                    = valor;    },

      //..................................
    
      p10StrDistribuidor(state,valor)                   {   state.strDistribuidor                   = valor;     },
      p10StrDescripcionDistribuidor(state,valor)        {   state.strDescripcionDistribuidor        = valor;     },
      p10StrDistribuidorListaImagenColor(state,valor)   {   state.strDistribuidorListaImagenColor   = valor;     },
      p10StrNifDistribuidorOfuscado(state,valor)        {   state.strNifDistribuidorOfuscado        = valor;     },
      p10StrDistribuidorMapaAtribucion(state,valor)     {   state.strDistribuidorMapaAtribucion     = valor;     },
      p10StrDistribuidorUrlErp(state,valor)             {   state.strDistribuidorUrlErp             = valor;     },
      p10StrDistribuidorUrlMapa(state,valor)            {   state.strDistribuidorUrlMapa            = valor;     },
      p10StrDistribuidorUrlOptimizador(state,valor)     {   state.strDistribuidorUrlOptimizador     = valor;     },
      p10StrDistribuidorGrupoDispositivos(state,valor)  {   state.strDistribuidorGrupoDispositivos  = valor;     },

      p10StrLeyenda1(state,valor)                       {   state.strLeyenda1                       = valor;     },
      p10StrLeyenda2(state,valor)                       {   state.strLeyenda2                       = valor;     },
      p10StrLeyenda3(state,valor)                       {   state.strLeyenda3                       = valor;     },
      p10StrDatosDelProceso(state,valor)                {   state.strDatosDelProceso                = valor;     },

      //..................................

      p10StrIDSesion(state,valor)                       {   state.strIDSesion                       = valor;     },
      
      //=========================================================
      //== Cambio de Lista de DISTRIBUIDORES
      //=========================================================
      p10StrListaUD(state,valor)                   { 
        //console.log('-- ListaUD --');
        //console.dir(valor);  

        //-- Actualizar Lista Completa de Distribuidores y Artilugios
        state.strListaUD                 = valor;    

        //...................................................
        //-- Actualizar Lista de Distribuidores ÚNICOS
        //...................................................
        const resultado1 = [];
        const resultado2 = [];

        const mapa      = new Map();

        for (const item of valor) {
          if(!mapa.has(item.d1)){
            mapa.set(item.d1, true);      //-- Actualizar Mapa

            resultado1.push({            //-- Agregar Nuevo Elemento a la Lista
               cod: item.d1,
               des: item.d2,
               nif: item.d3,
               atr: item.d6,
               ure: item.d7,
               urm: item.d8,
               uro: item.d9,
               grp: item.dg,
               css: item.dcss,
               lic: item.dlic
            });

            resultado2.push({            //-- Agregar Nuevo Elemento a la Lista
              value: item.d1,
              label: item.d2
            });
          }
        }

        //............................................................
        //-- Actualizar la Nueva Lista de Distribuidores en el STORE
        //............................................................
        var resultado2Sorted = resultado2.sort(function(a, b){
                                                  var nameA=a.value.toLowerCase(), nameB=b.value.toLowerCase();
                                                  if (nameA < nameB) { return -1; }
                                                  if (nameA > nameB) { return  1; }
                                                  return 0; 
                                              });

        //-- Todos los Distribuidores (formato IH1)
        state.strListaDistribuidores               = resultado1.sort();

        //-- Todos los Distribuidores (formato VueSelectSides)
        state.strListaDistribuidoresSeleccionables = resultado2Sorted;

        //-- Lista de Distribuidores Seleccionados
        state.strListaDistribuidoresSeleccionados  = [];

        //.. Evaluar si se ha recibido, al menos, UN distribuidor
        if (resultado2Sorted.length > 0) {
          //-- Inicializar Lista de Distribuidores Seleccionados con el PRIMER elemento seleccionable
          state.strListaDistribuidoresSeleccionados.push(resultado2Sorted[0].value);
        }
      
        //console.log('-- Lista Distribuidores Seleccionados --');
        //console.dir(state.strListaDistribuidoresSeleccionados);
      },

      p10StrListaDispositivos(state,valor)                   {   state.strListaDispositivos                   = valor;    },

      p10StrListaDistribuidores(state,valor)                 {   state.strListaDistribuidores                 = valor;    },

      p10StrListaDistribuidoresSeleccionables(state,valor)   {   state.strListaDistribuidoresSeleccionables   = valor;    },
      p10StrListaDistribuidoresSeleccionados(state,valor)    {   state.strListaDistribuidoresSeleccionados    = valor;    },

      p10StrListaOperarios(state,valor)                      {   state.strListaOperarios                      = valor;    },
      p10StrListaCamiones(state,valor)                       {   state.strListaCamiones                       = valor;    },

      //..................................
      
      p10StrRecibiendoDatos(state,valor)                     {   state.strRecibiendoDatos                     = valor;    },
      p10StrIndicadorRefrescarVista(state,valor)             {   
        //console.log('p10StrIndicadorRefrescarVista');
        state.strIndicadorRefrescarVista  += valor;    
      },
      p10StrIndicadorPorcentajeActividad(state,valor)        {   state.strIndicadorPorcentajeActividad        = valor;    },

      //..................................


  },

  //-------------------------------------------------
  //-- Getters (Obtener Valor de Variables,
  //--          o resultados filtrados)
  //-------------------------------------------------
  getters : {

      strUsuarioAutenticado                   : state => state.strUsuarioAutenticado,
      strAbandonarSesion                      : state => state.strAbandonarSesion,
      strMenuPrincipal                        : state => state.strMenuPrincipal,

      strScreenWidth                          : state => state.strScreenWidth,
      strScreenHeight                         : state => state.strScreenHeight,

      //..................................

      strIndiceCartografia                    : state => state.strIndiceCartografia,
      strListaCartografias                    : state => state.strListaCartografias,

      //..................................

      strDesdeFechaConsulta                   : state => state.strDesdeFechaConsulta,
      strHastaFechaConsulta                   : state => state.strHastaFechaConsulta,
      strCalendarioListaImagenColor           : state => state.strCalendarioListaImagenColor,

      strLatitudCentroMapa                    : state => state.strLatitudCentroMapa,
      strLongitudCentroMapa                   : state => state.strLongitudCentroMapa,
      strZoomInicial                          : state => state.strZoomInicial,

      //..................................

      strDestinoSalidaHora                    : state => state.strDestinoSalidaHora,
      strDestinoSalidaMinutos                 : state => state.strDestinoSalidaMinutos,
      strDestinoSegundosParada                : state => state.strDestinoSegundosParada,

      strParadaSegundosFactorCliente          : state => state.strParadaSegundosFactorCliente,
      strParadaSegundosFactorArticulo         : state => state.strParadaSegundosFactorArticulo,
      strParadaSegundosCriterioArticulos      : state => state.strParadaSegundosCriterioArticulos,

      strRepartoTramosHorarios                : state => state.strRepartoTramosHorarios,

      strDocumentoAtributosMostrables         : state => state.strDocumentoAtributosMostrables,

      //..................................

      strUsuario                              : state => state.strUsuario,
      strDescripcionUsuario                   : state => state.strDescripcionUsuario,
      strMailUsuario                          : state => state.strMailUsuario,

      //..................................

      strDistribuidor                         : state => state.strDistribuidor,
      strDescripcionDistribuidor              : state => state.strDescripcionDistribuidor,
      strDistribuidorListaImagenColor         : state => state.strDistribuidorListaImagenColor,
      strNifDistribuidorOfuscado              : state => state.strNifDistribuidorOfuscado,
      strDistribuidorMapaAtribucion           : state => state.strDistribuidorMapaAtribucion,
      strDistribuidorUrlErp                   : state => state.strDistribuidorUrlErp,
      strDistribuidorUrlMapa                  : state => state.strDistribuidorUrlMapa,
      strDistribuidorUrlOptimizador           : state => state.strDistribuidorUrlOptimizador,
      strDistribuidorGrupoDispositivos        : state => state.strDistribuidorGrupoDispositivos,

      strLeyenda1                             : state => state.strLeyenda1,
      strLeyenda2                             : state => state.strLeyenda2,
      strLeyenda3                             : state => state.strLeyenda3,
      strDatosDelProceso                      : state => state.strDatosDelProceso,
      
      //..................................

      strIDSesion                             : state => state.strIDSesion,
      strListaUD                              : state => state.strListaUD,
      strListaDispositivos                    : state => state.strListaDispositivos,
      strListaDistribuidores                  : state => state.strListaDistribuidores,

      strListaDistribuidoresSeleccionables    : state => state.strListaDistribuidoresSeleccionables,
      strListaDistribuidoresSeleccionados     : state => state.strListaDistribuidoresSeleccionados,

      strListaOperarios                       : state => state.strListaOperarios,
      strListaCamiones                        : state => state.strListaCamiones,

      //..................................

      strRecibiendoDatos                      : state => state.strRecibiendodatos,
      strIndicadorRefrescarVista              : state => state.strIndicadorRefrescarVista,
      strIndicadorPorcentajeActividad         : state => state.strIndicadorPorcentajeActividad,

      //..................................


  },

  //-------------------------------------------------------------
  //-- Actions (Métodos para alterar el valor de las Variables)
  //-------------------------------------------------------------
  actions : {

    //-- Ejemplo de Acciones : Llamada a sucesivos (commit) en el interior del (Store)
    /*
    p20SiguienteMensaje(context) {      
      if (context.state.strHeadMsgTexto.length > 0) {
        context.commit('p10StrHeadMsgTexto', '');
        context.commit('p10StrIndicadorRefrescarVista', 1);
      }      
    }
    */

  }

 
});

//Vue.prototype.$igs_store = igsStore
//module.exports = igsStore;

export default igs_store 

